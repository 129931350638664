import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const BlogPostCard = ({category, title, caption, slug, img}) => {
  

  return (
    <li className="card-blog">
      <div >
        <Img fluid={img.fluid} className="blog-img" />
      </div>
      <div className="u-p-1">
        <h3 className="blog-category main-type main-type__blog">
          {category}
        </h3>
        <h3 className="blog-title u-mt-1 main-type main-type__heavy main-type__black">
          {title}
        </h3>
        <p className="blog-caption u-mt-1 main-type main-type__black main-type__body">
          {caption}
        </p>
        <div className="u-mt-1">
          <Link
            className="blog-cta u-mt-1 main-type main-type__heavy main-type__primary-blue-2"
            to={slug}
          >
            Read More
          </Link>
        </div>
      </div>
    </li>
  );
};

export default BlogPostCard;
