import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import Button from "components/Button"
import BlogPostCard from "components/BlogPostCard"
import Pager from "components/Pager"

const Blog = ({ data, pageContext }) => {
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
    searchClass: "main-type search-label",
    searchVal: "",
    allPosts: "btn btn-blog btn-blog__active",
    labPosts: "btn btn-blog ",
    tabPosts: "btn btn-blog ",
  })

  const handleInputChange = event => {
    const query = event.target.value
    const posts = data.allStrapiPosts.edges || []

    const filteredData = posts.filter(posts => {
      const { title, caption, category } = posts.node
      return (
        title.toLowerCase().includes(query.toLowerCase()) ||
        caption.toLowerCase().includes(query.toLowerCase()) ||
        category.toLowerCase().includes(query.toLowerCase())
      )
    })

    setState({
      query,
      filteredData,
      searchClass:
        query === ""
          ? "main-type search-label"
          : " main-type search-label search-label__active",
      tabPosts: "btn btn-blog ",
      labPosts: "btn btn-blog ",
      allPosts: "btn btn-blog btn-blog__active",
    })
  }

  const returnLabPosts = () => {
    const posts = data.allStrapiPosts.edges || []

    const filteredData = posts.filter(post => {
      const { category } = post.node

      return category === "labSafety"
    })

    setState({
      query: "",
      filteredData,
      searchClass: "main-type search-label",
      searchVal: "",
      tabPosts: "btn btn-blog ",
      labPosts: "btn btn-blog btn-blog__active",
      allPosts: "btn btn-blog ",
    })
  }
  const returnTabPosts = () => {
    const posts = data.allStrapiPosts.edges || []

    const filteredData = posts.filter(post => {
      const { category } = post.node

      return category === "tabTesting"
    })

    setState({
      query: "",
      filteredData,
      searchClass: "main-type search-label",
      searchVal: "",
      tabPosts: "btn btn-blog btn-blog__active",
      labPosts: "btn btn-blog ",
      allPosts: "btn btn-blog ",
    })
  }
  const returnAllPosts = () => {
    const posts = data.allStrapiPosts.edges || []

    setState({
      query: "",
      filteredData: posts,
      searchClass: "main-type search-label",
      searchVal: "",
      tabPosts: "btn btn-blog ",
      labPosts: "btn btn-blog ",
      allPosts: "btn btn-blog btn-blog__active",
    })
  }

  const {
    filteredData,
    query,
    searchClass,
    searchVal,
    allPosts,
    tabPosts,
    labPosts,
  } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const hasCategoryResults = filteredData.length !== 0
  const renderPosts =
    hasSearchResults || hasCategoryResults
      ? filteredData
      : data.allStrapiPosts.edges

  return (
    <Layout pageName="home">
      <Helmet>
        <title>BalCon | Tab Testing Services</title>
      </Helmet>
      <div className="">
        <div className="blog-banner">
          <div className="u-abs-center u-center-text">
            <h1 className="main-type main-type__heavy main-type__white main-type__header-primary">
              BalCon Blog
            </h1>
            <p className="main-type main-type__white main-type__body u-mt-3">
              We write monthly content about best practices for Lab Safety and
              TAB Testing. Do you have question for us? Use the form below and
              we will be in touch!
            </p>
            <AnchorLink to="/blog/#contactForm" title="Schedule Service">
              <Button
                text="Ask us a Question"
                color="green"
                primary={true}
                margin="u-mt-3"
              />
            </AnchorLink>
          </div>
        </div>
        <div className="blog-container">
          <div className="blog-btn-container ">
            <div className="grid grid-two-col">
              <div>
                <button className={allPosts} onClick={returnAllPosts}>
                  All
                </button>
                <button className={labPosts} onClick={returnLabPosts}>
                  Lab Safety
                </button>
                <button className={tabPosts} onClick={returnTabPosts}>
                  TAB Testing
                </button>
              </div>
              <div className="search-container">
                <input
                  type="text"
                  name="search"
                  className={`main-type main-type__body main-type__secondary search-input`}
                  onChange={handleInputChange}
                  label="Search"
                  autoComplete="off"
                  value={searchVal}
                />
                <label htmlFor="search" className={searchClass}>
                  {" "}
                  Search Posts
                </label>
              </div>
            </div>
          </div>
          <ol className="grid grid-three-col">
            {renderPosts.map(post => {
              const category =
                post.node.category === "labSafety"
                  ? "Lab Safety"
                  : "TAB Testing"
              return (
                <BlogPostCard
                  key={post.node.id}
                  title={post.node.title}
                  category={category}
                  caption={post.node.caption}
                  img={post.node.featureImage.childImageSharp}
                  slug={post.node.slug}
                />
              )
            })}
          </ol>
          {renderPosts.length === 0 ? (
            <div className="blog-noposts u-center-text">
              <div className="u-abs-center">
                <h1 className="main-type main-type__heavy main-type__black main-type__header-primary">
                  Can't find what you're looking for?
                </h1>
                <AnchorLink to="/blog/#contactForm" title="Schedule Service">
                  <Button
                    text="Ask us a Question"
                    color="green"
                    primary={true}
                    margin="u-mt-1"
                  />
                </AnchorLink>
              </div>
            </div>
          ) : (
            ""
          )}
          <Pager pageContext={pageContext} />
        </div>
      </div>
      {/* Anchor for Smooth Scroll */}
      <div id="contactForm"></div>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiPosts(
      sort: { fields: publishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          caption
          category
          featureImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          altText
          slug
        }
      }
    }
  }
`
