import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import "assets/stylesheets/application.scss"
import Header from "components/Header"
import Footer from "components/Footer"

const getImages = graphql`
  {
    callIcon: file(relativePath: { eq: "call.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    envelopeIcon: file(relativePath: { eq: "envelope.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mapIcon: file(relativePath: { eq: "maps-and-flags.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footerBlob: file(relativePath: { eq: "footerBlob.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    balconLogo: file(relativePath: { eq: "balcon-logo-white.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rmsLogo: file(relativePath: { eq: "rms-logo-white.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Layout = ({ children, pageName }) => {
  const imgData = useStaticQuery(getImages)

  return (
    <>
      <div className="container">
        <div className="wrapper">
          <Header />
          <main>{children}</main>

          <Footer
            callIcon={imgData.callIcon.childImageSharp.fluid}
            envelopeIcon={imgData.envelopeIcon.childImageSharp.fluid}
            mapIcon={imgData.mapIcon.childImageSharp.fluid}
            footerBlob={imgData.footerBlob.childImageSharp.fluid}
            balconLogo={imgData.balconLogo.childImageSharp.fixed}
            rmsLogo={imgData.rmsLogo.childImageSharp.fixed}
          />
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
