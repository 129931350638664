import React from "react"
import { Link } from "gatsby"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <div className="u-center-text">
      {previousPagePath && (
        <span
          className="btn btn-simple btn-simple__secondary"
          style={{ margin: "0rem 2rem" }}
        >
          <Link to={previousPagePath}>Prev</Link>{" "}
        </span>
      )}
      {nextPagePath && (
        <span
          className="btn btn-simple btn-simple__secondary"
          style={{ margin: "0rem 2rem" }}
        >
          <Link to={nextPagePath}>Next</Link>{" "}
        </span>
      )}
    </div>
  )
}

export default Pager
